import * as request from '@/utils/request'

export default {
    /**
     * 获取红人
     * @returns
     */
    modelGetKolList(data) {
        return request.post('/kol_analy/artist_list', data)
    },
    /**
     * 获取红人
     * @returns
     */
    modelGetAllKolList() {
        return request.post('/kol_analy/all_artist')
    },
    /**
     * 新增红人模型数据-月
     * @returns
     */
    modeladdKolData(data) {
        return request.post('/kol_analy/create', data)
    },
    /**
     * 获取部门组
     * @returns
     */
    modelGetDepList(data) {
        return request.post('/kol_analy/dept_list', data)
    },
    /**
     * 获取模型里列表
     * @returns
     */
    modelGetModelList(data) {
        return request.post('/kol_analy/list', data)
    },
    /**
     * 获取模型详情
     * @returns
     */
    modelGetModelDetail(data) {
        return request.post('/kol_analy/detail', data)
    },
    /**
     * 获取模型title
     * @returns
     */
    modelGetModelTitle(data) {
        return request.post('kol_analy/head', data)
    },
    /**
     * 修改/ 红人模型 /无子项
     * @returns
     */
    modelReviseTotal(data) {
        return request.post('/kol_analy/update', data)
    },
    /**
     * 修改/ 红人模型 /有子项
     * @returns
     */
    modelRevise(data) {
        return request.post('/kol_analy_item/update', data)
    },
    /**
     * 删除/ 红人模型 /子项
     * @returns
     */
    modelDelChildData(data) {
        return request.post('/kol_analy_item/delete', data)
    },
    /**
     * 新增/ 红人模型 /
     * @returns
     */
    modelCreateData(data) {
        return request.post('/kol_analy_item/create', data)
    },
    /**
     * 刷新月份
     * @returns
     */
    modelRefresh(data) {
        return request.post('/kol_analy_item/sync', data)
    },

    /**
     * 修改头部信息
     * @returns
     */
    modelUpdateArtist(data) {
        return request.post('/artist/update', data)
    },
    /**
     * 删除单条list用户
     * @returns
     */
    modelKolDel(data) {
        return request.post('/kol_analy/delete', data)
    },
    /**
     * 操作记录
     * @returns
     */
    modelUserLog(data) {
        return request.post('/kol_analy_log/list', data)
    },
    /**
     * 获取同步数据
     * @returns
     */
    modelSyncData(data) {
        return request.post('/kol_analy_item/sync_data', data)
    },
    /**
     * 获取项目组列表
     * @returns
     */
    modelGroupList(data) {
        return request.post('/kol_group/list', data)
    },
    /**
     * 获取项目组详情
     * @returns
     */
    modelGroupDetail(data) {
        return request.post('/kol_group/summary', data)
    },
    /**
     * 获取单个项目组全年的数据
     * @returns
     */
    modelGetYear(data) {
        return request.post('/kol_group/all_year', data)
    },
    /**
     * 获取团队信息
     * @returns
     */
    modelGetTeamInfo(data){
        return request.post('/kol_analy_groups/info', data)
    },
    /**
     * @description 导出红人模型列表
     * @param {*} data
     * @returns
     */
    exportKolModelList(data) {
        return request.postBlob('/kol_analy/list',data)
    },
    /**
     * @description 导出人工成本
     * @param {*} data
     * @returns
     */
    exportKolModelLabor(data) {
        return request.postBlob('/kol_analy/download',data)
    },
    /**
     * @description 导出红人模型项目组列表
     * @param {*} data
     * @returns
     */
    exportKolGroupList(data) {
        return request.postBlob('/kol_group/list', data)
    },
    /**
     * @description 同步初始化数据
     * @param {*} data
     * @returns
     */
    kolModelInit(data) {
        return request.post('/kol_analy_setting/init', data)
    },
    /**
     * @description 同步初始化数据
     * @param {*} data
     * @returns
     */
    kolModelEmpty(data) {
        return request.post('/kol_analy_setting/delete', data)
    },
    /**
     * @description 同步获取初始化数据
     * @param {*} data
     * @returns
     */
    kolModelGetInitList(data) {
        return request.post('/kol_analy_setting/list', data)
    },
    /**
     * @description 同步上月列表
     * @param {*} data
     * @returns
     */
    kolModelGetInitsyncLast(data) {
        return request.post('/kol_analy_setting/sync_last', data)
    },
    /**
     * @description 同步获取批量设置
     * @param {*} data
     * @returns
     */
    kolModelGetInitbatch(data) {
        return request.post('/kol_analy_setting/batch_setting', data)
    },
    /**
     * @description 保存站点配置信息
     * @author YM
     * @date 2019-03-18
     * @param {*} data
     * @returns
     */
    kolModelSaveSetting(data) {
        return request.post('/kol_analy_setting/save', data)
    },
    /**
     * @description 红人模型配置-红人列表
     * @author YM
     * @date 2019-03-18
     * @param {*} data
     * @returns
     */
    kolModelartistlist(data) {
        return request.post('/kol_analy_setting/artist_list', data)
    },
    kolGroupMonthlist(data) {
        return request.post('/kol_approve/listGroupMonth', data)
    },
// /kol_analy_setting/artist_list
//     admin_api/kol_analy_setting/delete


//
}
