import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 新建询单项目
   * @param {*} data
   * @returns
   */
  saveInquiryProject(data) {
    return request.post('/inquiry_project/create', data)
  }, /**
   * 必填筛选条件
   * @description 修改询单项目
   * @param {*} data
   * @returns
   */
  updateInquiryProject(data) {
    return request.post('/inquiry_project/update', data)
  }, /**
   * 必填筛选条件 询单-全部
   * @description
   * @param {*} data
   * @returns
   */
  listInquiryProject(data) {
    return request.post('/inquiry_project/all', data)
  }, /**
   * 必填筛选条件 询单-我发布的
   * @description
   * @param {*} data
   * @returns
   */
  listInquiryOwner(data) {
    return request.post('/inquiry_project/owner', data)
  }, /**
   * 必填筛选条件 项目详情
   * @description
   * @param {*} data
   * @returns
   */
  infoInquiryProject(data) {
    return request.post('/inquiry_project/info', data)
  }, /**
   * 必填筛选条件 项目详情-报名表
   * @description
   * @param {*} data
   * @returns
   */
  applyDetailInquiryList(data) {
    return request.post('/inquiry_apply_detail/apply_list', data)
  }, /**
   * 必填筛选条件 更改询单状态
   * @description
   * @param {*} data
   * @returns
   */
  switchInquiryStatus(data) {
    return request.post('/inquiry_project/status', data)
  }, /**
   * 必填筛选条件 更改询单状态
   * @description
   * @param {*} data
   * @returns
   */
  endInquiryStatus(data) {
    return request.post('/inquiry_project/finish', data)
  }, /**
   * 必填筛选条件 更改询单状态
   * @description
   * @param {*} data
   * @returns
   */
  cancelInquiryStatus(data) {
    return request.post('/inquiry_project/cancel', data)
  }, /**
   * 必填筛选条件 KOL报名列表
   * @description
   * @param {*} data
   * @returns
   */
  listApplyInquiry(data) {
    return request.post('/inquiry_apply_detail/list', data)
  }, /**
   * 必填筛选条件 （执行经纪）-KOL清单
   * @description
   * @param {*} data
   * @returns
   */
  InquiryProjectItemInfo(data) {
    return request.post('/inquiry_project_item/info', data)
  },
  InquiryKolList(data) {
    return request.post('/inquiry_apply/kol_list', data)
  }, /**
   * 必填筛选条件 （执行经纪）-KOL确认报名
   * @description
   * @param {*} data
   * @returns
   */
  InquiryEnroll(data) {
    return request.post('/inquiry_apply_detail/save', data)
  }, /**
   * 必填筛选条件 （执行经纪）-KOL确定预览
   * @description
   * @param {*} data
   * @returns
   */
  checkInquiry(data) {
    return request.post('/inquiry_apply/apply_list', data)
  }, /**
   * 导出报名表
   * @param data
   * @returns {Promise}
   */
  exportApplyDetail(data) {
    return request.postBlob('/inquiry_apply_detail/export/apply_list', data)
  }, /**
   * 导出报名表-是否能请求
   * @param data
   * @returns {Promise}
   */
  canApplyDetail(data) {
    return request.post('/inquiry_apply_detail/export/apply_list', data)
  }, /**
   * 通过审核
   * @param data
   * @returns {Promise}
   */
  employApply(data) {
    return request.post('/inquiry_apply_detail/employ', data)
  },

  /**
   * 批量-录用
   * @param data
   * @returns {Promise}
   */
  confirmApply(data) {
    return request.post('/inquiry_apply_detail/confirm', data)
  },

  /**
   *  意向达人筛选
   * @param data
   * @returns {Promise}
   */
  intentionInquiry() {
    return request.post('/inquiry_project/intention_list')
  }, /**
   *  请求总数
   * @param data
   * @returns {Promise}
   */
  applyCount(data) {
    return request.post('/inquiry_apply/apply_count', data)
  }, /**
   *  报名列表-已报名
   * @param data
   * @returns {Promise}
   */
  applyList(data) {
    return request.post('/inquiry_apply_detail/apply_list_me', data)
  }, /**
   *  报名列表-已报名
   * @param data
   * @returns {Promise}
   */
  cancelApply(data) {
    return request.post('/inquiry_apply_detail/cancel', data)
  },

  /**
   *  报名列表-取消报名-单个
   * @param data
   * @returns {Promise}
   */
  cancelStatus(data) {
    return request.post('/inquiry_apply_detail/status', data)
  }, /**
   *  项目日志
   * @param data
   * @returns {Promise}
   */
  logsInquiry(data) {
    return request.post('/inquiry_project/logs', data)
  }, /**
   *  报名日志
   * @param data
   * @returns {Promise}
   */
  logsApply(data) {
    return request.post('/inquiry_apply/logs', data)
  }, /**
   *  删除项目
   * @param data
   * @returns {Promise}
   */
  delInquiry(data) {
    return request.post('/inquiry_project/delete', data)
  }, /**
   *  单个确认录用
   * @param data
   * @returns {Promise}
   */
  singleConfirm(data) {
    return request.post('/inquiry_apply_detail/single_confirm', data)
  }, /**
   *  附件删除
   * @param data
   * @returns {Promise}
   */
  deleteInquiryFile(data) {
    return request.post('/inquiry_project_attachment/delete', data)
  }, /**
   *  初选信息通知
   * @param data
   * @returns {Promise}
   */
  employNotify(data) {
    return request.post('/inquiry_apply_detail/employ_notify', data)
  }, /**
   *  合作信息通知
   * @param data
   * @returns {Promise}
   */
  confirmNotify(data) {
    return request.post('/inquiry_apply_detail/confirm_notify', data)
  }, /**
   *  所有创建人筛选
   * @param data
   * @returns {Promise}
   */
  allCreated(data) {
    return request.post('/inquiry_project/all_created', data)
  }, /**
   *  所有创建人筛选
   * @param data
   * @returns {Promise}
   */
  ownerGroup(data) {
    return request.post('/inquiry_project/owner_group', data)
  }, /**
   *  我的创建
   * @param data
   * @returns {Promise}
   */
  myCounts() {
    return request.post('/inquiry_project/owner_group_count')
  }, /**
   *  保存授权
   * @param data
   * @returns {Promise}
   */
  saveCharging(data) {
    return request.post('/charging_standard/save', data)
  }, /**
   *  读取授权
   * @param data
   * @returns {Promise}
   */
  chargingList() {
    return request.post('/charging_standard/list')
  }, /**
   * 记录导出
   * @param data
   * @returns {Promise}
   */
  recordExportInquiry(data) {
    return request.post('/inquiry_project/record_export', data)
  },
  /**
   * 确认下单
   * @param data
   * @returns {Promise}
   */
  setConfirmInquiryProject(data) {
    return request.post('/inquiry_project/set_confirm_order', data)
  },
  /**
   * 取消确认下单
   * @param data
   * @returns {Promise}
   */
  setUnConfirmInquiryProject(data) {
    return request.post('/inquiry_project/set_un_confirm_order', data)
  },
  /**
   * 获取询单产品下拉
   * @param data
   * @returns {Promise}
   */
  getInquiryProductOption(data) {
    return request.post('/inquiry_project/get_product_option', data)
  }
}
