import webSocket from '@/utils/webSocket'
import { getCurrentUserInfo } from '@/utils/auth'
import { Base64 } from 'js-base64'

class Message {

    ws

    timer = null

    interval = 10 * 1000

    constructor() {
        let isStart=`${process.env.VUE_APP_WS_START}`
        console.log('isStart',isStart)
        if (isStart==1){
            this.init()
        }

    }

    init() {
        let wsUrl
        if (process.env.NODE_ENV === 'development') {
            wsUrl = `${process.env.VUE_APP_WS_URL}/io`
        } else {
            const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
            const hostname = window.location.hostname
            wsUrl = `${wsProtocol}//${hostname}${process.env.VUE_APP_WS_URL}/io`
        }
        const user_info = getCurrentUserInfo()
        const uid = user_info ? user_info['id'] : ''
        if (uid) {
            const base64Uid = Base64.encode(uid)
            const base64UidStr = encodeURIComponent(base64Uid.substr(0, base64Uid.length - 1))
            this.ws = new webSocket(
                wsUrl + `?uid=${base64UidStr}`, {
                    // eslint-disable-next-line no-unused-vars
                    onOpen: _ => {
                        console.log('已成功连接到消息服务器...')
                    },
                    // eslint-disable-next-line no-unused-vars
                    onError: _ => {
                        console.log('未成功连接到消息服务器...')
                    },
                    // eslint-disable-next-line no-unused-vars
                    onClose: _ => {
                        console.log('与消息服务器断开...')
                    }
                }
            )
            this.ws.heartbeat.openHeartbeat = false
        } else {
            console.log('未获得用户信息...')
        }
    }

    getMessage() {
        this.timer = setInterval(() => {
            this.ws.send({ event: 'get_unread_message' })
        }, this.interval)
    }

    connection() {
        if (this.ws)
            this.ws.connection()
        else {
            console.log('ws未实例化...')
            this.init()
            console.log('ws实例化完毕...')
            this.ws.connection()
        }
    }

    close() {
        if (this.ws)
            this.ws.close()
    }

    //发送消息
    sendMsg(data) {
        this.ws.send(data)
    }

    //订阅事件
    subEvt(evtName) {
        this.ws.send({ event: evtName })
    }

    //绑定事件
    onEvt(ev, callback) {
        if (this.ws)
            this.ws.on(ev, callback)
        else
            console.log('事件订阅失败，websocket未连接...')
    }

    //解绑事件
    rmEvt(ev){
        if (this.ws)
            this.ws.rmOn(ev)
    }
    /**
     * 获取webSocket绑定的事件
     * @returns {{}|*|{}|{}}
     */
    getOnEvents() {
        if (this.ws)
            return this.ws.getOnEvents()
        else
            return {}
    }

}

export default Message
