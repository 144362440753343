import * as request from '@/utils/request'

export default {
    /**
     * @description 获取用户列表
     * @author YM
     * @date 2019-01-17
     * @returns promise
     */
    getUserList(data) {
        return request.post('/user/list', data)
    },
    /**
     * 记录用户菜单切换
     * @returns {Promise}
     */
    commitUserAction(to, from) {
        // return request.post('/user/user_action', { to: to, from: from })
        return request.post('/common/user_action', { to: to, from: from })
    },
    /**
     * @description 保存权限，新建、编辑的保存都走此方法，却别是有没有主键id
     * @author YM
     * @date 2019-01-19
     * @param {*} data
     * @returns
     */
    saveUser(data) {
        return request.post('/user/store', data)
    },
    /**
     * 修改用户角色
     * @param data
     * @returns {Promise}
     */
    editUserRole(data) {
        return request.post('/user/edit_role', { id: data.id, user_roles: data.user_roles })
    },
    /**
     * 更新用户信息
     * @param data
     * @returns {Promise}
     */
    updateUser(data) {
        //过滤字段
        const fields = ['id', 'mobile', 'email', 'nickname', 'avatar', 'job_number', 'name']
        if (!data['id']) {
            this.msgWarn('仅支持修改用户信息（不支持创建用户）')
            return false
        }
        let postData = {}
        fields.forEach((f) => {
            if (data[f])
                postData[f] = data[f]
        })
        return request.post('/user/update', postData)
    },
    /**
     * 用户绑定钉钉用ID
     * @param data
     * @returns {Promise}
     */
    bindDingUser(data) {
        return request.post('/user/bind_ding_user', data)
    },
    /**
     * @description 根据id获取单条信息，编辑使用
     * @author YM
     * @date 2019-01-28
     */
    getUserInfo(id) {
        let data = { id: id }
        return request.post('/user/get_info', data)
    },
    /**
     * 用户额外信息
     * @param id
     * @returns {Promise}
     */
    getUserExtraInfo(id) {
        let data = { id: id }
        return request.post('/user/get_extra_info', data)
    },
    /**
     * @description 根据id删除单条信息
     * @param {*} data
     * @returns
     */
    deleteUser(id) {
        let data = { id: id }
        return request.post('/user/delete', data)
    },
    /**
     * @description 根据id删除单条信息
     * @returns
     */
    changePwdUser(id, pwd) {
        let data = { id: id, pwd: pwd }
        return request.post('/user/change_pwd', data)
    },
    /**
     * 修改用户状态
     * @param userId
     * @param status
     * @returns {Promise}
     */
    changeUserStatus(userId, status) {
        const data = {
            id: userId,
            user_status: status
        }
        return request.post('/user/change_status', data)
    },
    /**
     * @description 用户绑定用户组，获取信息
     * @author YM
     * @date 2019-01-26
     * @returns
     */
    getUserRoles() {
        return request.post('/user/get_roles')
    },
    /**
     * @description 搜索用户
     * @param {*} qs
     * @returns
     */
    searchUser(qs) {
        let data = { search: qs }
        return request.post('/user/search_user', data)
    },
    /**
     *
     * @param qs
     * @returns {Promise}
     */
    searchUserCommon(qs) {
        let data = { search: qs }
        return request.post('/common/user_search', data)
    },
    /**
     * @description 搜索用户-钉钉id
     * @param {*} qs
     * @returns
     */
    searchUserDing(qs) {
        let data = { search: qs }
        return request.post('/ding_user_info/search_user', data)
    },
    /**
     * @description 用户下拉
     * @param data
     */
    optionsUser(data) {
        return request.post('/ding_user_info/search_user', data)
    },
    /**
     * @description 我的分组
     *
     * @returns
     */
    myGroups() {
        return request.post('/user/my_groups', {})
    },
    /**
     * 获取用户关联的分组
     * @param data
     * @returns {Promise}
     */
    getUserGroups(id) {
        let data = { user_id: id }
        return request.post('/user/get_groups', data)
    },

    /**
     * @description 为用户添加关联组
     * @param {*} data
     * @returns
     */
    saveUserGroups(data) {
        return request.post('/user/save_groups', data)
    },
    /**
     * @description 获取用户钉钉免密登录日志列表
     * @returns promise
     */
    getUserLoginRecord(data) {
        return request.post('/user/login_record', data)
    },

    /**
     * @description 获取用户登录日志（全部/详细）
     * @returns promise
     */
    getUserLoginLog(data) {
        return request.post('/user/login_log', data)
    },
    getUserPurviewList(data) {
        return request.post('/user/purview', data)
    },
    /**
     *
     * @param data
     * @returns {Promise}
     */
    getUserPermissionRange(data) {
        return request.post('/user/permission_range', data)
    },
    /**
     * 用户登录饼图
     * @param data
     * @returns {Promise}
     */
    getUserLoginLogChartData(data) {
        return request.post('/user/login_log_chart_data', data)
    },
    getUserEditLog(data) {
        return request.post('/user/edit_log', data)
    },
    /**
     *
     * @returns {Promise}
     * @param userid
     */
    getUserDingTalkLeaveInfo(userid) {
        return request.post('/user/get_dingtalk_leave_info', { userid: userid })
    },
    /**
     * 获取用户曾任职部门
     * @param user_id
     * @returns {Promise}
     */
    getUserDeptList(user_id) {
        return request.post('/user/prev_dept_list', { user_id: user_id })
    },
    /**
     * 导出用户列表
     * @param
     * @returns {Promise}
     */
    exportUserList(searchCondition) {
        return request.postBlob('/user/export', searchCondition)
    },
// /user/export
    /**
     * 系统用户下拉列表
     * @returns {Promise}
     */
    getUserOption() {
        return request.post('/user/get_user_option', {})
    },
}
