import * as request from '@/utils/request'

export default {
  //人工成本
  laborGetKolList(data) {
    return request.post('/kol_analy_labor_cost/list', data)
  }, laborEdit(data) {
    return request.post('/kol_analy_labor_cost/save', data)
  },

  laborDel(data) {
    return request.post('/kol_analy_labor_cost/delete', data)
  },

  laborgetImportList(data) {
    return request.post('/kol_analy_labor_cost/list', data)
  }, laborDelImport(data) {
    return request.post('/kol_analy_labor_cost/delete_import', data)
  }, //商务支持团队
  supportTeamGetKolList(data) {
    return request.post('/kol_analysis_basic_expenses_by_bst/list', data)
  },

  supportTeamEdit(data) {
    return request.post('/kol_analysis_basic_expenses_by_bst/save', data)
  },

  supportTeamDel(data) {
    return request.post('/kol_analysis_basic_expenses_by_bst/delete', data)
  },

  supportTeamgetImportList(data) {
    return request.post('/kol_analysis_basic_expenses_by_bst/list', data)
  },

  supportTeamDelImport(data) {
    return request.post('/kol_analysis_basic_expenses_by_bst/delete_import', data)
  }, //返点费用
  rebateGetKolList(data) {
    return request.post('/kol_analy_rebate/list', data)
  }, rebateEdit(data) {
    return request.post('/kol_analy_rebate/save', data)
  },

  rebateDel(data) {
    return request.post('/kol_analy_rebate/delete', data)
  },

  exportRebateList(data) {
    return request.postBlob('/kol_analy_rebate/download', data)
  },

  rebateDelImport(data) {
    return request.post('/kol_analy_rebate/delete_import', data)
  }, //MCN推广费用
  mcnPromotionGetKolList(data) {
    return request.post('/kol_analy_mcn/list', data)
  },

  mcnPromotionEdit(data) {
    return request.post('/kol_analy_mcn/save', data)
  },

  mcnPromotionDel(data) {
    return request.post('/kol_analy_mcn/delete', data)
  }, mcnPromotiongetImportList(data) {
    return request.post('/kol_analy_mcn/list', data)
  },

  mcnPromotionDelImport(data) {
    return request.post('/kol_analy_mcn/delete_import', data)
  }, exportMcnPromotionTemplate() {
    return request.postBlob('/kol_analy_mcn/download')
  },

  //直播投放金
  liveFundingGetKolList(data) {
    return request.post('/kol_analy_live_put/list', data)
  },

  liveFundingEdit(data) {
    return request.post('/kol_analy_live_put/save', data)
  },

  liveFundingDel(data) {
    return request.post('/kol_analy_live_put/delete', data)
  },

  liveFundinggetImportList(data) {
    return request.post('/kol_analy_live_put/list', data)
  },

  liveFundingDelImport(data) {
    return request.post('/kol_analy_live_put/delete_import', data)
  }, exportliveFundingTemplate() {
    return request.postBlob('/kol_analy_live_put/download')
  },

  //红人模型-网红分成
  analysisDivideIntoGetKolList(data) {
    return request.post('/kol_analysis_basic_expenses_by_aob/list', data)
  },

  analysisDivideIntoEdit(data) {
    return request.post('/kol_analysis_basic_expenses_by_aob/save', data)
  },

  analysisDivideIntoDel(data) {
    return request.post('/kol_analysis_basic_expenses_by_aob/delete', data)
  },

  analysisDivideIntogetImportList(data) {
    return request.post('/kol_analysis_basic_expenses_by_aob/list', data)
  },

  analysisDivideIntoDelImport(data) {
    return request.post('/kol_analysis_basic_expenses_by_aob/delete_import', data)
  },
  //办公租金装修
  renovationRentGetKolList(data) {
    return request.post('/kol_analysis_basic_expenses_by_orr/list', data)
  },

  renovationRentEdit(data) {
    return request.post('/kol_analysis_basic_expenses_by_orr/save', data)
  },

  renovationRentDel(data) {
    return request.post('/kol_analysis_basic_expenses_by_orr/delete', data)
  },

  renovationRentgetImportList(data) {
    return request.post('/kol_analysis_basic_expenses_by_orr/list', data)
  },

  renovationRentDelImport(data) {
    return request.post('/kol_analysis_basic_expenses_by_orr/delete_import', data)
  },
  //管理服务费
  manageFeeGetKolList(data) {
    return request.post('/kol_analysis_basic_expenses_by_msf/list', data)
  },

  manageFeeEdit(data) {
    return request.post('/kol_analysis_basic_expenses_by_msf/save', data)
  },

  manageFeeDel(data) {
    return request.post('/kol_analysis_basic_expenses_by_msf/delete', data)
  },

  manageFeegetImportList(data) {
    return request.post('/kol_analysis_basic_expenses_by_msf/list', data)
  },

  manageFeeDelImport(data) {
    return request.post('/kol_analysis_basic_expenses_by_msf/delete_import', data)
  }

}
