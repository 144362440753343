export default {
  path: 'kolModel',
  name: 'modelIndex',
  component: () => import('@/pages/kolModel/KolList'),
  meta: { title: '红人模型' },
  children: [
    // {
    //   path: 'index',
    //   name: 'modelIndex',
    //   component: () => import('@/pages/kolModel/KolList'),
    //   meta: { title: '数据模型-红人列表' }
    // },
    {
      path: ':id/modelDetail',
      name: 'modelDetail',
      component: () => import('@/pages/kolModel/KolDetail'),
      meta: { title: '数据模型-红人详情' }
    },
    {
      path: 'team',
      name: 'modelTeam',
      component: () => import('@/pages/kolModel/TeamList'),
      meta: { title: '数据模型-项目组列表' }
    },
    {
      path: ':id/teamDetail',
      name: 'modelTeamDetail',
      component: () => import('@/pages/kolModel/TeamDetail'),
      meta: { title: '数据模型-项目组详情' }
    },

    {
      path: 'artist_model',
      name: 'ArtistModel',
      component: () => import(/* webpackChunkName: "setting-site" */ '@/pages/kolModel/components/ArtistModel'),
      meta: { title: '红人模型' },
    }
  ]
}
