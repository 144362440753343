export default {
  path: 'enrollIndex',
  name: 'enrollIndex',
  component: () => import('@/pages/inquiries/enrollIndex'),
  meta: { title: '询单报名' },
  children: [
    {
      //inquiryApplyList
      path: 'list',
      name: 'inquiryEnrollList',
      component: () => import('@/pages/inquiries/inquiryApplyList'),
      meta: { title: '普通询单' }
    },
    {
      //inquiryApplyList
      path: 'yearEnroll',
      name: 'yearEnrollInquiryList',
      component: () => import('@/pages/inquiries/yearEnrollInquiryList'),
      meta: { title: '年框大全案' }
    },
    // {
    //   path: ':id/inquiriesDetail',
    //   name: 'inquiriesDetail',
    //   component: () => import('@/pages/inquiries/InfoDetail'),
    //   meta: { title: '项目详情' }
    // },
    {
      path: ':id/applyDetail',
      name: 'enrollDetail',
      component: () => import('@/pages/inquiries/applyDetail'),
      meta: { title: '项目详情' }
    }
    ,
    {
      path: 'chargingStandard',
      name: 'chargingStandard',
      component: () => import('@/pages/inquiries/chargingStandard'),
      meta: { title: '授权费用管理' }
    }
    //  applyDetail
  ]
}
