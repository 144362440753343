export default {
  path: 'analysis_basic',
  name: 'analysis_basic',
  component: () => import('@/pages/analysis_basic/index'),
  meta: { title: '基础数据' },
  children: [
    {
      path: 'labor_cost',
      name: 'labor_cost',
      component: () => import('@/pages/analysis_basic/labor_cost/index'),
      meta: { title: '人工成本' }
    },
    {
      path: ':id/labor_cost_import',
      name: 'labor_cost_import',
      component: () => import('@/pages/analysis_basic/labor_cost/importTable.vue'),
      meta: { title: '人工成本导入列表' }
    },
    {
      path: 'business_support_team',
      name: 'business_support_team',
      component: () => import('@/pages/analysis_basic/business_support_team/index'),
      meta: { title: '商务支持团队费用' }
    },
    {
      path: ':id/business_support_team_import',
      name: 'business_support_team_import',
      component: () => import('@/pages/analysis_basic/business_support_team/importTable.vue'),
      meta: { title: '商务支持团队费用导入列表' }
    },
    {
      path: 'rebate',
      name: 'rebate',
      component: () => import('@/pages/analysis_basic/rebate/index'),
      meta: { title: '返点费用' }
    },
    {
      path: ':id/rebate_import',
      name: 'rebate_import',
      component: () => import('@/pages/analysis_basic/rebate/importTable.vue'),
      meta: { title: '返点费用导入列表' }
    },
    {
      path: 'mcn_promotion',
      name: 'mcn_promotion',
      component: () => import('@/pages/analysis_basic/mcn_promotion/index'),
      meta: { title: 'MCN推广' }
    },
    {
      path: ':id/mcn_promotion',
      name: 'mcn_promotion_import',
      component: () => import('@/pages/analysis_basic/mcn_promotion/importTable.vue'),
      meta: { title: 'MCN推广费导入列表' }
    },
    {
      path: 'live_funding',
      name: 'live_funding',
      component: () => import('@/pages/analysis_basic/live_funding/index'),
      meta: { title: '直播投放金' }
    },
    {
      path: ':id/live_funding',
      name: 'live_funding_import',
      component: () => import('@/pages/analysis_basic/live_funding/importTable.vue'),
      meta: { title: '直播投放金导入列表' }
    },
    {
      path: 'divide_into',
      name: 'divide_into',
      component: () => import('@/pages/analysis_basic/divide_into/index'),
      meta: { title: '红人模型-网红分成' }
    },
    {
      path: ':id/divide_into',
      name: 'divide_into_import',
      component: () => import('@/pages/analysis_basic/divide_into/importTable.vue'),
      meta: { title: '网红分成导入列表' }
    },
    {
      path: 'divide_into',
      name: 'divide_into',
      component: () => import('@/pages/analysis_basic/divide_into/index'),
      meta: { title: '红人模型-网红分成' }
    },
    {
      path: ':id/divide_into',
      name: 'divide_into_import',
      component: () => import('@/pages/analysis_basic/divide_into/importTable.vue'),
      meta: { title: '网红分成导入列表' }
    },
    {
      path: 'renovation_rent',
      name: 'renovation_rent',
      component: () => import('@/pages/analysis_basic/renovation_rent/index'),
      meta: { title: '办公租金装修' }
    },
    {
      path: ':id/renovation_rent',
      name: 'renovation_rent_import',
      component: () => import('@/pages/analysis_basic/renovation_rent/importTable.vue'),
      meta: { title: '办公租金装修导入列表' }
    }
    ,
    {
      path: 'manage_fee',
      name: 'manage_fee',
      component: () => import('@/pages/analysis_basic/manage_expense/index'),
      meta: { title: '管理服务费' }
    },
    {
      path: ':id/manage_fee',
      name: 'manage_fee_import',
      component: () => import('@/pages/analysis_basic/manage_expense/importTable.vue'),
      meta: { title: '管理服务费导入列表' }
    },
    {
      path: 'expense_index',
      name: 'expense_index',
      component: () => import('@/pages/analysis_basic/expense_index/index'),
      meta: { title: '报销明细' }
    },
  ]
}
