import * as request from '@/utils/request'

export default {

  /**
   * 必填筛选条件
   * @description 获取产品库数据列表
   * @param {*} data
   * @returns
   */

  getProductAuditList(data) {
    return request.post('/product_check/list', data)
  },

  //  根据商品id，查询详情
  getProductInfoById(data) {
    return request.post('/product_check/info', data)
  },

  //  审核通过
  productCheckAgree(data) {
    return request.post('/product_check/agree', data)
  },

  //  审核拒绝
  productCheckRefuse(data) {
    return request.post('/product_check/refuse', data)
  },
  //  退回重审
  productCheckRetrial(data) {
    return request.post('/product_check/retrial', data)
  },

  //信息更新
  prodectCheckUpdate(data) {
    return request.post('/product_check/update', data)
  },

  //商品审核负责人列表
  productCheckLeaders() {
    return request.post('/product_check/leaders', {})
  },
  //商家品牌列表
  getMerchantList(data) {
    return request.post('/merchant/list', data)
  },
  //商家品牌列表
  deleteMerchantInfo(id) {
    return request.post('/merchant/delete', {id:id})
  }
}
