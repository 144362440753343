export default {
  path: 'pa',
  name: 'pa',
  component: () => import('@/pages/pa/PaIndex'),
  meta: { title: '绩效管理' },
  children: [
    {
      path: 'kol',
      name: 'pa_kol',
      component: () => import('@/pages/pa/kol/PaKolIndex'),
      meta: { title: '绩效目标一览', alive: true }
    },
    {
      path: 'dept',
      name: 'pa_dept',
      component: () => import('@/pages/pa/dept/PaDeptIndex'),
      meta: { title: '项目组绩效', alive: true }
    },
    {
      path: 'chief',
      name: 'pa_chief',
      component: () => import('@/pages/pa/chief/PaChiefIndex'),
      meta: { title: '总监绩效', alive: true }
    },
    {
      path: 'bonus',
      name: 'pa_bonus',
      component: () => import('@/pages/pa/bonus/PaBonusIndex'),
      meta: { title: '奖金明细', alive: true }
    }, {
      path: 'video_case',
      name: 'video_case',
      component: () => import('@/pages/pa/case/VideoCase'),
      meta: { title: '抖音视频案例', alive: true }
    }

  ]
}
